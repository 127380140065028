@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

html, body {
  font-family: 'Inter', sans-serif;
  height: 100%;
  background-color: #FCFCFF;
}

*, *::after, *::before {
  padding: 0;
  margin: 0;
}

/* svg {
  width: 100%;
  height: 100%;
} */

ul {
  list-style: none;
}

table {
  width: 100%;
}

input:focus, input:active {
  outline: none;
  border-radius: 0;
  border: none;
}

textarea {
  border: none;
  border-radius: 0;
  outline: none;
  resize: none;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

button:focus,
button:active {
  border: none;
  outline: none;
}

#root {
  height: 100%; 
}

.visibility_msg {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Start NavBar */

.top__Navbar {
  position: fixed;
  width: 100%;
  z-index: 9999;
}

.top__Navbar > div {
  display: flex;
  align-items: center;
  padding: 8px 20px;
  justify-content: space-between;
  background-color: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
}

.top__Navbar .logo {
  width: 40px;
  height: 40px;
}

.top__Navbar  .logo > img {
  width: 100%;
  height: 100%;
}

.top__Navbar .top__NavContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}

.top__Navbar .top__NavContent .Nav__search,
.inputField__container {
  background-color: var(--light-grey);
  gap: 5px;
  padding: 0px 8px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.top__Navbar .top__NavContent .Nav__search > span,
.inputField__container > span {
  display: inline-flex;
  width: 22px;
  height: 22px;
  align-items: center;
}

.top__Navbar .top__NavContent .Nav__search input,
.inputField__container input {
  background-color: var(--light-grey);
  color:var(--dark-grey);
}

.top__NavContent .NavContent__right {
  display: flex;
  gap: 90px;
  align-items: center;
}


.top__NavContent .NavContent__right .iconTray {
  display: flex;
  gap: 16px;
  align-items: center;
}

.top__NavContent .NavContent__right .iconTray button {
  width: 30px;
  height: 30px;
  position: relative;
}

.top__NavContent .NavContent__right .iconTray button > span {
  width: 100%;
  height: 100%;
  display: inline-flex;
}

.top__NavContent .NavContent__right .iconTray button > span svg {
  width: 100%;
  height: 100%;
}

.top__NavContent .NavContent__right .iconTray button > span.indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-flex;
  background-color: var(--light-red-color);
  position: absolute;
  top: -3px;
  right: -5px;
}

.top__NavContent .NavContent__right .userBlock {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

.top__NavContent .NavContent__right .userBlock .userProfileImg {
  width: 40px;
  height: 40px;
  background-color: var(--dark-grey);
  border-radius: 8px;
}

.userBlock .userProfileImg {
  width: 40px;
  height: 40px;
  background-color: var(--dark-grey);
  border-radius: 4px;
}

.userBlock .userProfileName {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.userBlock .userProfileName > span:first-of-type {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.5px;
}

.userBlock .userProfileName > span:last-of-type {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.3px;
  color: var(--dark-grey);
}

.userBlock .dropDown {
  position: absolute;
  background-color: #FFF;
  top: 40px;
  border: 1px solid #4E4F4E;
  width: 200px;
  right: 0px;
  border-radius: 5px;
  display: none;
}

.userBlock .dropDown.active {
  display: block;
}

.userBlock .dropDown .userInfo,
.userBlock .dropDown ul {
  padding: 15px 20px;
}

.userBlock .dropDown .divider {
  height: 1px;
  background-color: #4E4F4E;
}

.side__Navbar {
  position: fixed;
  height: 100%;
  left: 0px;
  top: 0px;
  background-color: var(--light-red-color);
  overflow-x: hidden;
  padding: 0px 20px;
  width: 35px;
  transition: width 0.5s ease;
}

.side__Navbar.active {
  width: 170px;
  transition: width 0.5s ease;
}

.side__Navbar > div {
  padding-top: 100px;
}

.side__Navbar ul {
  list-style: none;
}

.side__Navbar ul > li {
  margin-bottom: 10px;
}

.side__Navbar ul > li a,
.userBlock .dropDown ul > li a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  text-decoration: none;
  padding: 5px;
  border-radius: 5px;
}

.side__Navbar ul > li a.active,
.side__Navbar ul > li a:hover {
  background-color: rgba(255, 255, 255, 0.30);
}

.side__Navbar.active ul > li a,
.userBlock .dropDown ul > li a {
  justify-content: flex-start;
}

.side__Navbar ul > li a > span:first-of-type,
.userBlock .dropDown ul > li a > span:first-of-type {
  width: 22px;
  height: 22px;
  display: inline-flex;
}

.side__Navbar ul > li a > span:first-of-type svg,
.userBlock .dropDown ul > li a > span:first-of-type svg {
  width: 100%;
  height: 100%;
}

.side__Navbar ul > li a > span:last-of-type,
.userBlock .dropDown ul > li a > span:last-of-type {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.5px;
  color: #FFF;
  display: none;
}

.userBlock .dropDown ul > li a > span:last-of-type {
  color: var(--dark-grey);
}

.side__Navbar.active ul > li a > span:last-of-type,
.userBlock .dropDown ul > li a > span:last-of-type {
  display: initial;
}

.side__Navbar  .divider {
  height: 1px;
  background-color: #EB606A;
  margin-top: 20px;
  margin-bottom: 20px;
}

.chevron_close_open {
  position: absolute;
  background-color: #EB606A;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  top: 17px;
  left: 80px;
  transform: rotate(0deg);
  transition: transform 0.5s ease;
}

.chevron_close_open.active {
  transform: rotate(180deg);
  transition: transform 0.5s ease;
}
/* End NavBar */

/* Start Login Page */

.login__page {
  height: 100%;
  background-color: var(--red-color);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.login__page > .login__logo {
  position: absolute;
  top: 30px;
  left: 32px;
  width: 50px;
  height: 50px;
}

.login__page > .login__logo > img {
  width: 100%;
  height: 100%;
}


.login__page > .login_title {
  position: absolute;
  top: 30px;
  color: #FFF;
}

.login__page > .login__body {
  width: 320px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.30);
  box-shadow: 4px 4px 50px 0px rgba(51, 49, 49, 0.25);
  backdrop-filter: blur(15px);
  padding: 40px 32px;
}

.login__page > .login__body h2 {
  color: #FFF;
  font-size: 39px;
  font-weight: 600;
  letter-spacing: -0.8px;
  margin-bottom: 34px;
}

.login__page > .login__body .login__input {
  background-color: #FFF;
  margin-bottom: 33px;
  gap: 10px;
  padding: 5px 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.login__page > .login__body .login__input > span {
  display: inline-flex;
  width: 24px;
  height: 24px;
  align-items: center;
}

.login__page > .login__body .login__input > span svg {
  width: 100%;
  height: 100%;
}

.top__Navbar .top__NavContent .Nav__search > input,
.login__page > .login__body .login__input > input {
  width: 100%;
  padding: 8px 0px;
  border: none;
  border-radius: 0px;
  color: #000;
}

.login__page > .login__body .login__input > input::-moz-placeholder {
  color:var(--grey-color);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.4px;
}

.login__page > .login__body .login__input > input::-webkit-input-placeholder {
  color:var(--grey-color);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.4px;
}

.login__button button {
  width: 100%;
  background-color: #000;
  border-radius: 8px;
  font-size: 19px;
  font-weight: 400;
  letter-spacing: -0.4px;
  color: #FFF;
  padding: 12px 0px;
  margin-top: 28px;
}

.login__page .log__arc {
  position: absolute;
  width: 280px;
}


.login__page .log__arc.arc1 {
  top: -100px;
  right: -50px;
  animation:spin1 6s linear infinite;
}

.login__page .log__arc.arc2 {
  top: -50px;
  right: -100px;
  animation:spin2 6s linear infinite;
}

.login__page .log__arc.arc3 {
  bottom: -50px;
  left: -120px;
  animation:spin1 6s linear infinite;
}

.login__page .log__arc.arc4 {
  bottom: -120px;
  left: -50px;
  animation:spin2 6s linear infinite;
}

@keyframes spin1 { 
  0% {
    -webkit-transform: rotate(360deg) translate(0px, 0px); 
    transform:rotate(360deg) translate(0px, 0px); 
  }
  50% {
    -webkit-transform: rotate(360deg) translate(-50px, -100px); 
      transform:rotate(360deg) translate(-50px, -100px); 
  }
  100% { 
    -webkit-transform: rotate(360deg) translate(0px, 0px); 
    transform:rotate(360deg) translate(0px, 0px); 
  }
}

@keyframes spin2 { 
  0% {
    -webkit-transform: rotate(360deg) translate(0px, 0px); 
    transform:rotate(360deg) translate(0px, 0px); 
  }
  50% {
    -webkit-transform: rotate(360deg) translate(-50px, -100px); 
      transform:rotate(360deg) translate(-50px, -100px); 
  }
  100% { 
    -webkit-transform: rotate(360deg) translate(0px, 0px); 
    transform:rotate(360deg) translate(0px, 0px); 
  }
}

/* End Login Page */


/* General Body */
.body__container {
  min-height: calc(100% - 105px);
  padding: 20px;
  padding-top: 85px;
  padding-left: 95px;
  transition: all 0.5s ease;
  padding-bottom: 20px;
}
.body__container.expand {
  padding-left: 230px;
  transition: all 0.5s ease;
}

.ModalParent {
  position: fixed;
  width: 0;
  height: 0;
  left: -300px;
  top: 0;
  z-index: 9999999;
  visibility: hidden;
  transition: visibility 1s ease;
}

.ModalParent.active {
  width: 100%;
  height: 100%;
  left:0;
  visibility: visible;
  transition: visibility 1s ease;
  
}

.ModalParent .ModalContainer {
  width: 100%;
  height: 100%;
}

.ModalParent .ModalContainer .loadingView,
.ModalParent .ModalContainer .ViewContainer {
  width: 100%;
  height: 100%;
  backdrop-filter: blur(12px);
  background-color: rgb(0 0 0 / 12%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ModalParent.active .ModalContainer .loadingView,
.ModalParent.active .ModalContainer .ViewContainer {
  opacity: 0;
  animation-name: fadeIn;
  animation-timing-function: linear;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.ModalParent .ModalContainer .loadingView > div {
  background: #FFF;
  border-radius: 10px;
  width: 50px;
  height: 50px;
  padding: 20px;
}

.ModalParent .ModalContainer .loadingView > div img {
  width: 100%;
  height: 100%;
  animation-name: zoomInOut;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform: scale(1);
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.input__pop {
  width: 300px;
  background-color: #FFF;
  padding: 25px;
  border-radius: 15px;
}

.input__pop > h4 {
  margin-bottom: 20px;
}

.status__container {
  width: 110px;
  padding: 8px 8px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.3px;
  text-align: center;
  border-radius: 8px;
  text-transform: capitalize;
}

.status__container.completed {
  background-color: var(--accent-900);
  color: #FFF;
}

.status__container.pending {
  background-color: var(--secondary-500);
  color: #FFF;
}

.status__container.cancel {
  background-color: var(--red-color);
  color: #FFF;
}

.status__container.greyed {
  background-color: var(--dark-grey);
  color: #FFF;
}

.status__container.notsent {
  background-color: var(--light-red-color);
  color: #FFF;
}

.edit__delete_action {
  display: flex;
  align-items: center;
  gap: 10px;
}

.edit__delete_action > button {
  width: 20px;
  height: 20px;
}

.edit__delete_action > button > span {
  width: 100%;
  height: 100%;
}

.edit__delete_action > button > span svg {
  width: 100%;
  height: 100%;
}

.pageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pageHeader h3 {
  color: var(--neutral-600);
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.5px;
}

.pageHeader p {
  color: var(--dark-grey);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.3px;
}

.pageHeader .product_create_button,
.product_add_button {
  border: 1px solid var(--light-red-color);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 6px 16px;
  gap: 5px;
  color: var(--light-red-color);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.4px;
}

.pageHeader .product_create_button > span {
  display: flex;
  align-items: center;
}

.custom__CheckBox {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 15px;
  height: 15px;
}

.custom__CheckBox input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  cursor: pointer;
}

.custom__CheckBox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 13px;
  width: 13px;
  border: 2px solid var(--neutral-500);
  background-color: transparent;
  border-radius: 5px;
}

.custom__CheckBox input:checked ~ .checkmark {
  background-color: var(--light-red-color);
  border: 2px solid var(--light-red-color);
}

.custom__CheckBox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom__CheckBox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom__CheckBox .checkmark:after {
  left: 4px;
  top: 2px;
  width: 3px;
  height: 6px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.inputFieldMain {
  width: fit-content;
  position: relative;
}

.searchdrop {
  position: relative;
}

.inputFieldMain > .labelText {
  margin-bottom: 10px;
}

.inputFieldMain label,
.checkfield {
  color: var(--neutral-400);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.4px;
}

.inputField__container {
  border: 1px solid var(--neutral-300);
  background-color: #FFF;
  padding: 2px 8px;
  justify-content: space-between;
}

.inputField__container input {
  border: none;
  background-color: transparent;
  padding: 5px 0px;
  width: 100%;
}

.inputField__container input::placeholder {
  color: #999C99;
}

.inputField__container input::-moz-placeholder {
  color: #999C99;
}

.inputFieldMain .inputDropDown,
.searchdrop .inputDropDown {
  position: absolute;
  width: 100%;
  background-color: #FFF;
  display: none;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  z-index: 99;
}

.inputFieldMain.inputDropDownMain .dropValue {
  color: var(--semi-dark-grey);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.4px;
  padding: 5px 0px;;
}

.inputFieldMain.inputDropDownMain .inputField__container.dropField {
  max-height: 100px;
  overflow: hidden;
}

.inputFieldMain .inputDropDown.active,
.searchdrop .inputDropDown.active {
  display: initial;
  max-height: 180px;
  overflow-x: hidden;
}

.inputFieldMain .inputDropDown button,
.searchdrop .inputDropDown button {
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  padding: 7px 5px;
  text-align: start;
}


.inputFieldMain .inputDropDown li.active button, 
.inputFieldMain .inputDropDown button:hover,
.searchdrop .inputDropDown li.active button, 
.searchdrop .inputDropDown button:hover {
  color: #FFF;
  background-color: var(--secondary-400);
}

.inputFieldMain .inputField__container.file_upload {
  position: relative;
}

.inputFieldMain .inputField__container.file_upload > div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.inputFieldMain .inputField__container.file_upload > div > span:first-of-type {
  width: 42px;
  height: 42px;
  display: inline-flex;
}

.inputFieldMain .inputField__container.file_upload > div > span:first-of-type > svg {
  width: 100%;
  height: 100%;
}

.inputFieldMain .inputField__container.file_upload > div > span {
  color: var(--neutral-600);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.3px;
}

.inputFieldMain .inputField__container.file_upload > input {
  visibility: hidden;
  position: absolute;
}

.export_data__container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.export_data__container > button {
  width: 24px;
  height: 24px;
  display: inline-flex;
}

.export_data__container > button svg {
  width: 100%;
  height: 100%;
}

.Toastify__toast-container {
  z-index: 999999999;
}

.component_menu {
  width: 100%;
  text-align: left;
  padding: 8px 12px;
  background-color: var(--neutral-800);
  border-radius: 5px;
  color: #FFF;
  font-size: 18px;
  font-weight: 400;
  margin-top: 20px;
}

.component_menu:active {
  opacity: 0.6;
}

.component_menu:first-of-type {
  margin-top: 0px;
}

.product_order__container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
}

.product_order__item {
  width: 100%;
  height: 100%;
  scroll-snap-align: start;
  transform-origin: center start;
  flex-shrink: 0;
  overflow-x: hidden;
}

.richText .quill {
  width: 100%;
  height: 100%;
}

.richText .quill .ql-toolbar.ql-snow{
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  border: none;
}

.richText .quill .ql-container.ql-snow {
  border: none;
}

.message__container {
  max-height:200px; 
  overflow-x:hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.message__container .messageItem {
  border-left: 2px solid #ec212f;
  padding: 5px 10px;
  max-width: 40%;
  background-color: #ffd8db;
  border-radius: 10px;
}

.message__container .messageItem.sender {
  border-left: 2px solid #808280;
  background-color: #eaeaea;
  margin-left: auto;
}

.message__container .messageItem .timer {
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 5px;
}

.message__container .messageItem .message {
  font-size: 14px;
}
/* General Body */

/* Start Dashboard */
.col__stats,
.col__summary {
  display: flex;
  gap: 20px;
}

.col__summary {
  margin-top: 40px;
}

.col__stats > .col__statsItem {
  width: 25%;
  background-color: var(--dark-grey);
  border-radius: 8px;
  display: flex;
  padding: 15px 20px;
  align-items: center;
  justify-content: space-between;
}

.col__stats > .col__statsItem .col__statsItem__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #FFF;
}


.col__stats > .col__statsItem .col__statsItem__content > span:first-of-type {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.7px;
}

.col__stats > .col__statsItem .col__statsItem__content > span:last-of-type {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.3px;
}

.col__stats > .col__statsItem .col__statsItem__icon {
  width: 48px;
  height: 48px;
}

.col__stats > .col__statsItem .col__statsItem__icon > svg {
  width: 100%;
  height: 100%;
}

.boxCard {
  border: 1px solid #000;
  width: 100%;
  border-radius: 8px;
  position: relative;
}

.boxCard > div {
  padding: 20px;
  overflow-y: hidden;
}

.card__header {
  margin-bottom: 10px;
}

.card__header {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card__header .card__title {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.4px;
}

.tableComponent {
  border-collapse: collapse;
}

.tableComponent th {
  text-align: start;
  background-color: #EB606A;
  padding: 5px 15px;
}

.tableComponent td {
  padding: 10px 15px;
  border-bottom: 1px solid var(--light-grey);
}

.tableComponent .headerText {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.4px;
}

.tableComponent .headerText.neutralText {
  color: #676967;
}
.tableComponent .headerText.white {
  color: #FFF;
}

.tableComponent .contentWithImg {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.tableComponent .contentWithImg > span:first-of-type {
  display: inline-flex;
  width: 35px;
  height: 35px;
  border-radius: 12px;
}

.tableComponent .contentWithImg > span:last-of-type,
.tableComponent td {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.3px;
}

.tableComponent .contentWithImg > span:first-of-type > img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.chartBody {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
}

.chartBody .chartLegend {
  padding: 6px 12px;
  border: 1px solid #EEBEC2;
  border-radius: 5px;
}

.chartBody .chartLegend > div {
  display: flex;
  gap: 5px;
  align-items: center;
}

.chartBody .chartLegend > div > span:first-of-type {
  width: 12px;
  height: 12px;
  display: inline-flex;
}

.chartBody .chartLegend > div > span:first-of-type > svg {
  width: 100%;
  height: 100%;
}

.chartBody .chartLegend > div > span:last-of-type {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.3px;
}

.chart__timeFrame {
  padding: 3px;
  display: flex;
  gap: 15px;
  align-items: center;
  border: 1px solid #CBCFCB;
  border-radius: 6px;
}

.chart__timeFrame > button {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.3px;
  color: var(--semi-dark-grey);
  padding: 5px 12px;
  transition: all 0.5s ease;
}

.chart__timeFrame > button.active {
  background-color: var(--semi-dark-grey);
  color: #FFF;
  border-radius: 6px;
  transition: all 0.5s ease;
}

.addedDropdown,
.multiAction,
.headerElemenDrop {
  position: relative;
}

.dropSpread {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.headerElemenDrop > button {
  display: flex;
  align-items: center;
  gap: 8px;
}

.addedDropdown > button,
.headerElemenDrop > button > span:last-of-type {
  transform: rotate(0deg);
  transition: all 0.5s ease;
}

.addedDropdown > button.active,
.headerElemenDrop > button.active > span:last-of-type {
  transform: rotate(180deg);
  transition: all 0.5s ease;
}

.headerElemenDrop > button > span:first-of-type {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.4px;
  color: #FFF;
}

.addedDropdown > div,
.multiAction > .listItem,
.headerElemenDrop > div {
  position: absolute;
  background-color: #FFF;
  width: max-content;
  z-index: 99;
  right: 0px;
  padding: 10px 0px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  display: none;
  transition: all 0.5s ease;
}

.addedDropdown > div.active,
.multiAction > .listItem.active,
.headerElemenDrop > div.active {
  display: block;
  transition: all 0.5s ease;
}

.addedDropdown > div li,
.multiAction > .listItem li,
.headerElemenDrop > div li a {
  padding: 5px 15px;
}

.addedDropdown > div li a,
.addedDropdown > div li button,
.multiAction > .listItem li button,
.headerElemenDrop > div li a {
  color: var(--dark-grey);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.4px;
  text-decoration: none;
}

.headerElemenDrop > div li a {
  display: inline-flex;
  width: 60px;
}

.addedDropdown > div li a:hover,
.addedDropdown > div li button:hover,
.multiAction > .listItem li button:hover {
  color: var(--light-red-color);
}

.headerElemenDrop > div li a:hover,
.headerElemenDrop > div li a.active {
  background-color: var(--secondary-400);
  color: #FFF;
}

.showMobile {
  display: none;
}

/* End Dashboard */


@media screen and (max-width:540px) {
  .login__page {
    padding: 20px;
  }
  .login__page > .login__body {
    position: relative;
    z-index: 99;
    width: 100%;
  } 
  .login__page > .login_title {
    top: 90px;
  }

  .login__page > .login_title > h1 {
    font-size: 28px;
    text-align: center;
  }

  .iconTray {
    display: none!important;
  }

  .showMobile {
    display: block;
  }

  .side__Navbar {
    left: -200px;
    z-index: 999;
  }
  .side__Navbar.active {
    left: 0px;
  }

  .body__container,
  .body__container.expand {
    padding-left: 20px;
  }

  .col__stats, .col__summary {
    flex-wrap: wrap;
  }
  .col__stats > .col__statsItem {
    width: calc(50% - 50px);
  }

  .col__summary > div {
    width: 100%!important;
  }

  .card__header .card__title {
    font-size: 14px;
  }

  .chart__timeFrame {
    gap: 10px;
  }

  .chart__timeFrame > button {
    padding: 5px 8px;
  }

  .chartBody .chartLegend {
    padding: 6px 8px;
  }

  .chartBody .chartLegend > div > span:last-of-type {
    font-size: 10px;
  }

  .mb-w-100 {
    width: 100%!important;
  }

  .mb-mt-14 {
    margin-top: 14px;
  }

  .mb-mt-24 {
    margin-top: 24px;
  }

  .mb-wrap {
    flex-wrap: wrap!important;
  }

  .profileHeader {
    padding-top: 50px!important;
    padding-inline: 5px!important;
  }

  .profileHeader > div:first-of-type {
    height: 70px!important;
  }

  .profileHeader .userInfo.userBlock {
    gap: 10px!important;
  }

  .profileHeader .userInfo.userBlock .userProfileImg {
    width: 55px!important;
    height: 55px!important;
  }

  .profileHeader .userInfo.userBlock > div:last-of-type > div:last-of-type {
    display: none!important;
  }

  .pageHeader > div {
    flex-direction: column;
    align-items: flex-start!important;
  }

  .mb-wrap {
    flex-wrap: wrap;
  }

  .expiry__pop {
    width: 80%!important;
  }
  
  .boxCard.expireTable {
    max-height: calc(100vh - 300px);
    overflow-x: hidden;
  }
}

@media screen and (max-width:375px) {
  .login__page > .login_title {
    top: 90px;
  }

  .login__page > .login_title > h1 {
    font-size: 22px;
    text-align: center;
  }
}