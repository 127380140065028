:root {
  --red-color: #CA0412;
  --light-red-color: #EB212F;
  --grey-color: #BCBEC0;
  --dark-grey:#808280;
  --light-grey: #E9E9E9;
  --semi-dark-grey: #999C99;
  --secondary-500: #EF5F0F;
  --secondary-400: #EB7839;
  --accent-900: #00983A;
  --neutral-600: #676967;
  --neutral-500: #CBCFCB;
  --neutral-300: #B2B5B2;
  --neutral-400: #999C99;
  --neutral-800: #353635;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
